import axios from ".";
import {
  GET_MILO_IMPORT,
  GET_MILO_TRANSFEREE_EXPORT,
  IMPORT_TRANSFEREES_XLSX,
} from "../resources/constants";

export default class importExportService {
  public static async syncMilo() {
    return await axios.get(GET_MILO_IMPORT);
  }

  public static async getMiloTransfereeCSV() {
    const headers = {
      responseType: "blob",
    };
    return await axios.get(GET_MILO_TRANSFEREE_EXPORT, headers);
  }

  public static async importTrasnferees(data: any) {
    return await axios.post(IMPORT_TRANSFEREES_XLSX, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}

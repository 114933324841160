import { Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { clearError } from "../../store/Login/slice";
import { useDispatch, useSelector } from "react-redux";
import { AddNewDivision } from "../Admin/NewDivision/AddNewDivision";
import { useAppSelector } from "../../store/hooks";
import SuspenseLoading from "../../components/SuspenseLoading/SuspenseLoading";
import { setAuthorized } from "../../store/Authorized/slice";

const NotificationListing = lazy(
  () => import("../Notification/NotificationListing"),
);
const NotificationCreateEdit = lazy(
  () => import("../Notification/NotificationCreateEdit"),
);

const SyncMilo = lazy(() => import("../SyncMilo/SyncMilo"));

const SupplierDashboard = lazy(
  () => import("../SupplierPortal/SupplierDashboard/SupplierDashboard"),
);

const SelfGuidedMoveHelp = lazy(
  () => import("../SelfGuidedMoveHelp/SelfGuidedMoveHelp"),
);
const MoveHelpDisplayPage = lazy(
  () => import("../SelfGuidedMoveHelp/MoveHelpDisplayPage"),
);
const RelocationRealtor = lazy(
  () => import("../SelfGuidedMoveHelp/RelocationRealtor"),
);
const PageNotFound = lazy(() => import("../PageNotFound/PageNotFound"));
const Unauthorized = lazy(() => import("../Unauthorized/Unauthorized"));

const SelfGuidedDashboard = lazy(
  () => import("../SelfGuidedDashBoard/SelfGuidedDashBoard"),
);

const SelfGuidedQuickMove = lazy(
  () => import("../SelfGuidedQuickMove/SelfGuidedQuickMove"),
);

const SelfGuidedDetailedMove = lazy(
  () => import("../SelfGuidedDetailedMove/SelfGuidedDetailedMove"),
);

const SelfGuidedExpenseForm = lazy(
  () => import("../SelfGuidedExpense/SelfGuidedExpenseForm"),
);

const SelfGuidedExpense = lazy(
  () => import("../SelfGuidedExpense/SelfGuidedExpense"),
);

const Dashboard = lazy(() => import("../dashboard/Dashboard"));
const ClientLanding = lazy(() => import("../Admin/DashBoard/ClientLanding"));
const ReportsPage = lazy(() => import("../Reports/ReportsPage"));
const TransfereeProfile = lazy(
  () => import("../TransfereeProfile/TransfereeProfile"),
);
const Transeferees = lazy(() => import("../Transferees/Transeferees"));
const AssignPolicy = lazy(
  () => import("../Divisions/AssignPolicy/AssignPolicy"),
);
const ExceptionListingPage = lazy(
  () => import("../ExceptionsPage/ExceptionListing/ExceptionListingPage"),
);
const CreateException = lazy(() => import("../ExceptionsPage/CreateException"));
const DivisionListing = lazy(() => import("../Divisions/DivisionListing"));
const CostEstimates = lazy(() => import("../CostEstimates/routes"));
const ClientPage = lazy(() => import("../Client/ClientPage"));
const AdminDashBoardPage = lazy(
  () => import("../Admin/DashBoard/AdminDashBoardPage"),
);
const DivisionView = lazy(
  () => import("../Divisions/DivisionView/DivisionView"),
);
const DivisionEdit = lazy(
  () => import("../Divisions/DivisionEdit/DivisionEdit"),
);
const BankingAccounts = lazy(
  () => import("../BankingInformation/BankingAccounts/BankingAccounts"),
);
const BankingView = lazy(
  () => import("../BankingInformation/BankingView/BankingView"),
);
const BankingEdit = lazy(
  () => import("../BankingInformation/BankingEdit/BankingEdit"),
);
const TransfereeTimeline = lazy(
  () => import("../TransfereeProfile/TransfereeTimeline/TransfereeTimeline"),
);
const FileShareListing = lazy(
  () => import("../FileShare/FileShareListing/FileShareListing"),
);
const FileShareTransferees = lazy(
  () => import("../FileShare/FileShareTransferees/FileShareTransferees"),
);
const CreateFolder = lazy(
  () => import("../FileShare/CreateFolder/CreateFolder"),
);
const FileDocuments = lazy(
  () => import("../FileShare/FileDocuments/FileDocuments"),
);
const TransfereeDashboard = lazy(
  () => import("../TransfereeDashBoard/TransfereeDashboard"),
);
const AuthorizeRelocation = lazy(
  () => import("../AuthorizeRelocation/AuthorizeRelocation"),
);
const TempLivingListing = lazy(
  () => import("../TempLiving/TempLivingListing/TempLivingListing"),
);
const TempLivingOptions = lazy(
  () => import("../TempLiving/TempLivingOptions/TempLivingOptions"),
);
const TempLivingOptionDetails = lazy(
  () => import("../TempLiving/TempLivingOptionDetails/TempLivingOptionDetails"),
);
const FinancialOverviewView = lazy(
  () =>
    import("../FinancialOverview/FinancialOverviewView/FinancialOverviewView"),
);
const TempLivingRequest = lazy(
  () => import("../TempLiving/TempLivingRequest/TempLivingRequest"),
);
const ExpenseSubmissionListing = lazy(
  () =>
    import(
      "../ExpenseSubmission/ExpenseSubmissionListing/ExpenseSubmissionListing"
    ),
);
const ExpenseSubmissionView = lazy(
  () =>
    import("../ExpenseSubmission/ExpenseSubmissionView/ExpenseSubmissionView"),
);
const ExpenseSubmissionFolder = lazy(
  () =>
    import(
      "../ExpenseSubmission/ExpenseSubmissionFolder/ExpenseSubmissionFolder"
    ),
);
const ClientContactListing = lazy(
  () => import("../ClientContact/ClientContactListing/ClientContactListing"),
);
const ClientContactView = lazy(
  () => import("../ClientContact/ClientContactView/ClientContactView"),
);
const ClientMemberListing = lazy(
  () => import("../ClientMember/ClientMemberListing/ClientMemberListing"),
);
const ClientMemberView = lazy(
  () => import("../ClientMember/ClientMemberView/ClientMemberView"),
);
const ClientMemberEdit = lazy(
  () => import("../ClientMember/ClientMemberEdit/ClientMemberEdit"),
);
const FinancialOverviewListing = lazy(
  () =>
    import(
      "../FinancialOverview/FinancialOverviewListing/FinancialOverviewListing"
    ),
);
const ExpenseSubmissionEdit = lazy(
  () =>
    import("../ExpenseSubmission/ExpenseSubmissionEdit/ExpenseSubmissionEdit"),
);
const CreateTempLivingRequest = lazy(
  () => import("../TempLiving/CreateTempLivingRequest/CreateTempLivingRequest"),
);
const SuppliersListing = lazy(
  () => import("../Suppliers/SuppliersListingPage/SuppliersListing"),
);
const SuppliersLanding = lazy(
  () => import("../Suppliers/SuppliersLanding/SuppliersLanding"),
);
const PropertyListing = lazy(
  () => import("../TempLiving/PropertyListing/PropertyListing"),
);

const Forbidden = lazy(() => import("../Forbidden/Forbidden"));
const ClientEdit = lazy(() => import("../ClientEdit/ClientEdit/ClientEdit"));

const TempLivingEmail = lazy(
  () => import("../TempLiving/TempLivingEmail/TempLivingEmail"),
);
const ClientConfigurationPage = lazy(
  () => import("../Client/ClientConfigurationPage"),
);
const ClientAddMember = lazy(
  () => import("../ClientMember/ClientAddMember/ClientAddMember"),
);
const ServicesListing = lazy(() => import("../Vendors/ServicesListing"));
const CreateCostEstimateConfig = lazy(
  () =>
    import(
      "../CostEstimatorConfig/CreateCostEstimateConfig/CreateCostEstimateConfig"
    ),
);
const CostEstimatorConfig = lazy(
  () => import("../CostEstimatorConfig/CostEstimatorConfig"),
);
const AddEditSupplier = lazy(
  () => import("../Suppliers/AddEditSupplier/AddEditSupplier"),
);
const VendorsListing = lazy(
  () => import("../Vendors/VendorsListing/VendorsListing"),
);
const CreateEditVendor = lazy(
  () => import("../Vendors/CreateEditVendor/CreateEditVendor"),
);
const PropertyDetail = lazy(
  () => import("../TempLiving/PropertyDetail/PropertyDetail"),
);
const PropertyEdit = lazy(
  () => import("../TempLiving/PropertyEdit/PropertyEdit"),
);
const StaffListingPage = lazy(() => import("../StaffPages/StaffListingPage"));
const ExpenseCategoryListingPage = lazy(
  () => import("../ExpenseCategoryPage/ExpenseCategoryListingPage"),
);
const SelectProperty = lazy(
  () => import("../TempLiving/SelectProperty/SelectProperty"),
);
const TloEdit = lazy(() => import("../TempLiving/TloEdit/TloEdit"));
const ExpenseTypeListingPage = lazy(
  () => import("../ExpenseTypePage/ExpenseTypeListingPage"),
);
const CreateExpenseCategory = lazy(
  () =>
    import(
      "../ExpenseCategoryPage/CreateExpensecategory/CreateExpenseCategory"
    ),
);
const StaffShowPage = lazy(() => import("../StaffPages/StaffShowPage"));
const CreateExpenseType = lazy(
  () => import("../ExpenseTypePage/CreateExpenseType/CreateExpenseType"),
);
const PolicyListing = lazy(
  () => import("../Policy/PolicyListing/PolicyListing"),
);
const PolicyView = lazy(() => import("../Policy/PolicyView/PolicyView"));
const PolicyEdit = lazy(() => import("../Policy/PolicyEdit/PolicyEdit"));
const CRIGroupListing = lazy(
  () => import("../CRIGroup/CRIGroupListing/CRIGroupListing"),
);
const CRIGroupView = lazy(
  () => import("../CRIGroup/CRIGroupView/CRIGroupView"),
);
const CRIGroupEdit = lazy(
  () => import("../CRIGroup/CRIGroupEdit/CRIGroupEdit"),
);
const CRIGroupAddMember = lazy(
  () => import("../CRIGroup/CRIAddMember/CRIAddMember"),
);
const CreateEditStaffPage = lazy(
  () => import("../StaffPages/CreateEditStaffPage"),
);
const AddNewMiloUser = lazy(
  () => import("../AddNewEmployee/AddMiloUser/AddNewMiloUser"),
);
const AddMiloTransferee = lazy(
  () =>
    import(
      "../AddNewEmployee/AddMiloUser/AddMiloTransfereeForm/AddMiloTransferee"
    ),
);
const BankInfoupdateListing = lazy(
  () => import("../BankInfoUpdates/BankInfoupdateListing"),
);
const BankingInformation = lazy(
  () => import("../BankingInformationPageFile/BankingInformation"),
);
const UserProfilePage = lazy(() => import("../UserProfile/UserProfile"));
const Help = lazy(() => import("../Help/Help"));
const WhatsNew = lazy(
  () => import("../WhatsNew/WhatsNewListing/WhatsNewListing"),
);
const SupportTeam = lazy(() => import("../SupportTeam/SupportTeam"));
const AdHocView = lazy(() => import("../AdHoc/AdHocView/AdHocView"));
const AdHocEdit = lazy(() => import("../AdHoc/AdHocEdit/AdHocEdit"));
const ExpenseAuthorizationView = lazy(
  () =>
    import(
      "../ExpenseAuthorization/ExpenseAuthorizationView/ExpenseAuthorizationView"
    ),
);

export default function AuthenticatedRoutes() {
  const login = useAppSelector((state: any) => state?.login);
  const [role, setRole] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setRole(login?.user?.role || "");
  }, [login]);

  useEffect(() => {
    dispatch(clearError());
  }, []);

  const location = useLocation();

  useEffect(() => {
    dispatch(setAuthorized(true));
  }, [location.pathname]);

  const authorized = useAppSelector((state: any) => state?.authorized);

  const getRoutes = (role: string, subrole: string = "") => {
    if (!authorized)
      return (
        <>
          <Suspense fallback={SuspenseLoading}>
            <Routes>
              <Route path="*" element={<Unauthorized />} />
            </Routes>
          </Suspense>
        </>
      );
    switch (role) {
      case "client": {
        return (
          <>
            <Suspense fallback={SuspenseLoading}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/reports" element={<ReportsPage />} />
                <Route path="/exceptions" element={<ExceptionListingPage />} />
                <>
                  {process.env.REACT_APP_SHOW_COST_ESTIMATE === "false" && (
                    <Route
                      path="/cost-estimates/*"
                      element={<PageNotFound />}
                    />
                  )}
                </>
                <Route path="/cost-estimates/*" element={<CostEstimates />} />
                <Route path="/user-profile" element={<UserProfilePage />} />
                <Route path={"/edit-exception"} element={<CreateException />} />
                <Route
                  path={"/exception-details"}
                  element={<CreateException />}
                />
                <Route
                  path={"/timeline/:id"}
                  element={<TransfereeTimeline />}
                />
                <Route path={"/transferees"} element={<Transeferees />} />
                <Route path={"/temp-living"} element={<TempLivingListing />} />
                <Route
                  path={"/temp-living-options/:id"}
                  element={<TempLivingOptions />}
                />
                <Route
                  path={"/temp-living-details/:id"}
                  element={<TempLivingOptionDetails />}
                />
                <Route path={"/file-share"} element={<FileShareListing />} />
                <Route path={"/create-folder/"} element={<CreateFolder />} />
                <Route path={"/file-documents/"} element={<FileDocuments />} />
                <Route
                  path={"/file-transferees/"}
                  element={<FileShareTransferees />}
                />
                <Route path={"/temp-living"} element={<TempLivingListing />} />
                <Route
                  path={"/add-employee"}
                  element={<AuthorizeRelocation />}
                />
                <Route
                  path={"/temp-living-options/:id"}
                  element={<TempLivingOptions />}
                />
                <Route
                  path={"/edit-transferee/:id"}
                  element={<AuthorizeRelocation />}
                />
                <Route
                  path={"/transferees/:id"}
                  element={<TransfereeProfile />}
                />
                <Route
                  path={"/transferee/:id"}
                  element={<TransfereeProfile />}
                />
                <Route path={"/adhoc/:id"} element={<AdHocView />} />
                <Route path={"/adhoc/:id/edit"} element={<AdHocEdit />} />
                <Route path={"/support-team/"} element={<SupportTeam />} />
                {/* <Route path={"/help"} element={<Help />} /> */}
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/forbidden" element={<Forbidden />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Suspense>
          </>
        );
      }
      case "admin": {
        return (
          <>
            <Suspense fallback={SuspenseLoading}>
              <Routes>
                <Route path={"/"} element={<AdminDashBoardPage />} />
                <Route path={"/dashboard"} element={<AdminDashBoardPage />} />
                <Route path={"/add-division/"} element={<DivisionEdit />} />
                <Route path={"/edit-division/"} element={<DivisionEdit />} />
                <Route path={"/division/:id"} element={<DivisionView />} />
                <Route path={"/divisions/:id"} element={<DivisionListing />} />
                <Route path={"/divisions"} element={<DivisionListing />} />
                <Route path={"/assign-policy"} element={<AssignPolicy />} />
                <Route path="/reports" element={<ReportsPage />} />
                <Route path="/exceptions" element={<ExceptionListingPage />} />
                <Route path="/sync-milo" element={<SyncMilo />} />
                <Route
                  path="/notifications"
                  element={<NotificationListing />}
                />
                <Route
                  path="/notification/create"
                  element={<NotificationCreateEdit />}
                />
                <Route
                  path="/notification/edit/:id"
                  element={<NotificationCreateEdit />}
                />
                <Route
                  path="/bank-info-updates"
                  element={<BankInfoupdateListing />}
                />
                <Route
                  path="/banking-information/:id"
                  element={<BankingInformation />}
                />
                <Route
                  path="/banking-information"
                  element={<BankingInformation />}
                />

                <Route
                  path={"/transferees/:id"}
                  element={<TransfereeProfile />}
                />
                <Route path={"/addDivision/:id"} element={<AddNewDivision />} />
                <Route
                  path={"/create-exception"}
                  element={<CreateException />}
                />
                <Route path={"/edit-exception"} element={<CreateException />} />
                <Route
                  path={"/exception-details"}
                  element={<CreateException />}
                />
                <Route path={"/clients"} element={<ClientPage />} />
                <Route path={"/client/:id"} element={<ClientLanding />} />
                <>
                  {process.env.REACT_APP_SHOW_COST_ESTIMATE === "false" && (
                    <Route
                      path="/cost-estimates/*"
                      element={<PageNotFound />}
                    />
                  )}
                </>
                <Route path="/cost-estimates/*" element={<CostEstimates />} />
                <Route path="/staff" element={<StaffListingPage />} />
                <Route path="/user-profile" element={<UserProfilePage />} />
                <Route path={"/adhoc/:id"} element={<AdHocView />} />
                <Route path={"/adhoc/:id/edit"} element={<AdHocEdit />} />
                <Route
                  path="/expense-types"
                  element={<ExpenseCategoryListingPage />}
                />
                <Route path="/staff-details/:id" element={<StaffShowPage />} />
                <Route
                  path="/add-expense-type/:categoryId"
                  element={<CreateExpenseType />}
                />
                <Route
                  path="/update-expense-type/:categoryId/:id"
                  element={<CreateExpenseType />}
                />
                <Route
                  path="/add-expense-category"
                  element={<CreateExpenseCategory />}
                />
                <Route
                  path="/update-expense-category/:id"
                  element={<CreateExpenseCategory />}
                />
                <Route
                  path="/manage-types/:id"
                  element={<ExpenseTypeListingPage />}
                />
                <Route
                  path={"/client-configuration"}
                  element={<ClientConfigurationPage />}
                />
                <Route path={"/client-edit/"} element={<ClientEdit />} />
                <Route path={"/temp-living"} element={<TempLivingListing />} />
                <Route
                  path={"/temp-living-options/:id"}
                  element={<TempLivingOptions />}
                />
                <Route path={"/vendors/:id"} element={<VendorsListing />} />
                <Route path={"/vendors"} element={<ServicesListing />} />
                <Route
                  path={"/cost-estimator-config/:type"}
                  element={<CostEstimatorConfig />}
                />

                <Route
                  path={"/cost-estimator-config/"}
                  element={<CostEstimatorConfig />}
                />
                <Route
                  path={"/cost-estimator-config/create"}
                  element={<CreateCostEstimateConfig />}
                />
                <Route
                  path={"/cost-estimator-config/edit"}
                  element={<CreateCostEstimateConfig />}
                />

                <Route
                  path={"/financial-overviewView/:id"}
                  element={<FinancialOverviewView />}
                />
                <Route
                  path={"/financial-overview/:id"}
                  element={<FinancialOverviewListing />}
                />
                <Route
                  path={"/financial-overview"}
                  element={<FinancialOverviewListing />}
                />
                <Route path={"/client-edit/"} element={<ClientEdit />} />
                <Route
                  path={"/client-contact/:id"}
                  element={<ClientContactListing />}
                />
                <Route
                  path={"/client-contact/:id/view"}
                  element={<ClientContactView />}
                />
                <Route
                  path={"/client-member/:id"}
                  element={<ClientMemberListing />}
                />
                <Route
                  path={"/client-member/:clientId/view/:id"}
                  element={<ClientMemberView />}
                />
                <Route
                  path={"/client-member/:id/edit"}
                  element={<ClientMemberEdit />}
                />
                <Route
                  path={"/client-member/:id/add-member"}
                  element={<ClientAddMember />}
                />

                <Route path={"/cri-group/:id"} element={<CRIGroupListing />} />
                <Route
                  path={"/cri-group/:clientId/view/:id"}
                  element={<CRIGroupView />}
                />
                <Route
                  path={"/cri-group/:id/edit"}
                  element={<CRIGroupEdit />}
                />
                <Route
                  path={"/cri-group/:id/add-member"}
                  element={<CRIGroupAddMember />}
                />
                <Route path={"/policies/:id"} element={<PolicyListing />} />
                <Route path={"/policy/:id"} element={<PolicyView />} />
                <Route path={"/policy-edit/"} element={<PolicyEdit />} />
                <Route
                  path={"/timeline/:id"}
                  element={<TransfereeTimeline />}
                />
                <Route
                  path={"/timeline/:id"}
                  element={<TransfereeTimeline />}
                />
                <Route path={"/transferees"} element={<Transeferees />} />
                <Route path={"/temp-living"} element={<TempLivingListing />} />
                <Route path={"/property-edit"} element={<PropertyEdit />} />
                <Route
                  path={"/temp-living-email"}
                  element={<TempLivingEmail />}
                />
                <Route
                  path={"/property-listing"}
                  element={<PropertyListing />}
                />
                <Route
                  path={"/select-property/"}
                  element={<SelectProperty />}
                />
                <Route path={"/tlo-edit/"} element={<TloEdit />} />
                <Route
                  path={"/property-detail/:id"}
                  element={<PropertyDetail />}
                />
                <Route path={"/suppliers"} element={<SuppliersListing />} />
                <Route path={"/add-supplier"} element={<AddEditSupplier />} />
                <Route
                  path={"/edit-supplier/:id"}
                  element={<AddEditSupplier />}
                />
                <Route path={"/add-vendor"} element={<CreateEditVendor />} />
                <Route
                  path={"/edit-vendor/:id"}
                  element={<CreateEditVendor />}
                />
                <Route
                  path={"/edit-staff/:id"}
                  element={<CreateEditStaffPage />}
                />
                <Route path={"/add-staff"} element={<CreateEditStaffPage />} />
                <Route
                  path={"/supplier-details/:id"}
                  element={<SuppliersLanding />}
                />
                <Route
                  path={"/temp-living/create-temp-living-request/:transfereeId"}
                  element={<CreateTempLivingRequest />}
                />
                <Route
                  path={
                    "/temp-living/update-temp-living-request/:transfereeId/:id"
                  }
                  element={<CreateTempLivingRequest />}
                />
                <Route
                  path={"/temp-living-details/:id"}
                  element={<TempLivingOptionDetails />}
                />
                <Route path={"/add-employee"} element={<AddNewMiloUser />} />

                <Route
                  path={"/add-transferee/:clientId"}
                  element={<AuthorizeRelocation />}
                />
                <Route
                  path={"/edit-transferee/:id"}
                  element={<AuthorizeRelocation />}
                />
                <Route
                  path={"/transferee/:id"}
                  element={<TransfereeProfile />}
                />
                <Route path={"/file-share"} element={<FileShareListing />} />
                <Route path={"/create-folder/"} element={<CreateFolder />} />
                <Route path={"/file-documents/"} element={<FileDocuments />} />
                <Route
                  path={"/file-transferees/"}
                  element={<FileShareTransferees />}
                />
                <Route
                  path={"/add-employee/:id"}
                  element={<AddMiloTransferee />}
                />
                <Route
                  path={"/expense-submission/"}
                  element={<ExpenseSubmissionListing />}
                />
                <Route
                  path={"/expense-authorization/:id"}
                  element={<ExpenseAuthorizationView />}
                />
                <Route
                  path={"/expense-submission/:id"}
                  element={<ExpenseSubmissionView />}
                />
                <Route
                  path={"/expense-submission-folder/:id"}
                  element={<ExpenseSubmissionFolder />}
                />
                <Route
                  path={"/expense-submission-edit/"}
                  element={<ExpenseSubmissionEdit />}
                />
                <Route path={"/news/"} element={<WhatsNew />} />

                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/forbidden" element={<Forbidden />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Suspense>
          </>
        );
      }
      case "transferee": {
        return (
          <>
            <Suspense fallback={SuspenseLoading}>
              <Routes>
                {login?.user?.policyType === "SG_LUMP_SUM" && (
                  <>
                    <Route path={"/"} element={<SelfGuidedDashboard />} />
                    <Route
                      path={"/dashboard"}
                      element={<SelfGuidedDashboard />}
                    />
                    <Route
                      path={"/quick-move-budget"}
                      element={<SelfGuidedQuickMove />}
                    />
                    <Route
                      path={"/detailed-move-budget"}
                      element={<SelfGuidedDetailedMove />}
                    />
                    <Route
                      path={"/add-track-expense/"}
                      element={<SelfGuidedExpenseForm />}
                    />
                    <Route
                      path={"/edit-track-expense/:id"}
                      element={<SelfGuidedExpenseForm />}
                    />
                    <Route
                      path={"/track-expense"}
                      element={<SelfGuidedExpense />}
                    />
                    <Route
                      path={"/self-guided-move-help"}
                      element={<SelfGuidedMoveHelp />}
                    />
                    <Route
                      path={"/move-help/:id"}
                      element={<MoveHelpDisplayPage />}
                    />
                    <Route
                      path={"/move-help"}
                      element={<SelfGuidedMoveHelp />}
                    />
                    <Route
                      path={"/relocation-realtor"}
                      element={<RelocationRealtor />}
                    />
                  </>
                )}
                <Route path={"/"} element={<TransfereeDashboard />} />
                <Route path={"/dashboard"} element={<TransfereeDashboard />} />
                <Route path={"/timeline"} element={<TransfereeTimeline />} />
                <Route
                  path={"/timeline/:id"}
                  element={<TransfereeTimeline />}
                />
                <Route
                  path={"/banking-accounts/"}
                  element={<BankingAccounts />}
                />
                <Route
                  path={"/banking-account/:id"}
                  element={<BankingView />}
                />
                <Route path={"/add-bank-account/"} element={<BankingEdit />} />
                <Route
                  path={"/edit-bank-account/:id"}
                  element={<BankingEdit />}
                />
                <Route path={"/file-share"} element={<FileShareListing />} />
                <Route path={"/create-folder/"} element={<CreateFolder />} />
                <Route path={"/file-documents/"} element={<FileDocuments />} />
                <Route path="/user-profile" element={<UserProfilePage />} />
                <Route
                  path={"/expense-submission/"}
                  element={<ExpenseSubmissionListing />}
                />
                <Route
                  path={"/expense-submission/:id"}
                  element={<ExpenseSubmissionView />}
                />
                <Route
                  path={"/expense-submission-folder/:id"}
                  element={<ExpenseSubmissionFolder />}
                />
                <Route
                  path={"/expense-submission-edit/"}
                  element={<ExpenseSubmissionEdit />}
                />
                <Route
                  path={"/transferees/:id"}
                  element={<TransfereeProfile />}
                />
                <Route
                  path={"/transferee/:id"}
                  element={<TransfereeProfile />}
                />
                <Route
                  path={"/financial-overview/"}
                  element={<FinancialOverviewView />}
                />
                <Route
                  path={"/financial-overview/:id"}
                  element={<FinancialOverviewView />}
                />
                <Route path={"/temp-living"} element={<TempLivingListing />} />
                <Route
                  path={"/temp-living-options/:id"}
                  element={<TempLivingOptions />}
                />
                <Route
                  path={"/temp-living-details/:id"}
                  element={<TempLivingOptionDetails />}
                />
                <Route
                  path={"/temp-living-request/:id"}
                  element={<TempLivingRequest />}
                />
                <Route
                  path="/bank-info-updates"
                  element={<BankInfoupdateListing />}
                />
                <Route
                  path="/banking-information/:id"
                  element={<BankingInformation />}
                />
                <Route
                  path="/banking-information"
                  element={<BankingInformation />}
                />
                {/* <Route path={"/help"} element={<Help />} /> */}

                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/forbidden" element={<Forbidden />} />
                <Route path="/vendors/:id" element={<MoveHelpDisplayPage />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Suspense>
          </>
        );
      }
      case "supplier": {
        return (
          <>
            <Suspense fallback={SuspenseLoading}>
              <Routes>
                <Route path={"/"} element={<SupplierDashboard />} />
                <Route path={"/dashboard"} element={<SupplierDashboard />} />
                <Route path="/user-profile" element={<UserProfilePage />} />
                <Route path={"/file-share"} element={<FileShareListing />} />
                <Route path={"/create-folder/"} element={<CreateFolder />} />
                <Route path={"/file-documents/"} element={<FileDocuments />} />
                <Route path="/help" element={<Help />} />
                <Route path="/properties" element={<PropertyListing />} />
                <Route
                  path={"/property-listing"}
                  element={<PropertyListing />}
                />
                <Route
                  path={"/select-property/"}
                  element={<SelectProperty />}
                />
                <Route path={"/tlo-edit/"} element={<TloEdit />} />
                <Route
                  path={"/property-detail/:id"}
                  element={<PropertyDetail />}
                />
                <Route path={"/property-edit"} element={<PropertyEdit />} />
                <Route path={"/temp-living"} element={<TempLivingListing />} />
                <Route
                  path={"/temp-living-options/:id"}
                  element={<TempLivingOptions />}
                />
                <Route
                  path={"/temp-living-details/:id"}
                  element={<TempLivingOptionDetails />}
                />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Suspense>
          </>
        );
      }
    }
  };

  return <div>{getRoutes(role)}</div>;
}
